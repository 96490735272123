.beneits-section {
  --benefits-pad-y: 1.25rem;

  @include media-breakpoint-only(xs) {
    margin-top: calc(var(--benefits-pad-y) * -2);
    position: relative;
    z-index: 2;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 10px;
    max-width: 1032px;
    margin: 0 auto;
    padding-top: var(--benefits-pad-y);
    padding-bottom: var(--benefits-pad-y);

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 15px;
    }
    @include media-breakpoint-up(md) {
      --benefits-pad-y: 50px;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
    }
    @include media-breakpoint-up(xl) {
      max-width: 1180px;
    }

    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #e4e4e4;
    }
  }
}
