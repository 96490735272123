.footer {
  position: relative;
  padding-top: $spacer * 2;
  padding-bottom: $spacer * 1.5;
  background-color: #4b4b99;
  background-image: url("../img/footer-logo-xs.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
  overflow: hidden;

  @include media-breakpoint-up(sm) {
    padding-top: $spacer * 3;
    padding-bottom: $spacer * 2.5;
    background-image: url("../img/footer-logo-sm.svg");
  }

  @include media-breakpoint-up(md) {
    background-image: url("../img/footer-logo.svg");
    background-size: 60% auto;
    background-position: 120% 0%;
  }

  &__title {
    margin-bottom: 1rem;
    font-family: "FavoritBookC", sans-serif;
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.6;
  }
  &__additional {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__external-links {
    flex-wrap: nowrap;
    > *:first-child {
      margin-right: $spacer;
    }
    @media screen and (max-width: 400px) {
      justify-content: space-between;
      > * {
        width: 48%;
        &:first-child {
          margin-right: 0;
        }
      }
    }
    @include media-breakpoint-only(sm) {
      flex-wrap: wrap;
      > * {
        margin-bottom: 1rem;
      }
    }
  }

  &__notes {
    position: relative;
    margin-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: #ffffff;
    @include media-breakpoint-up(sm) {
      margin-top: 1rem;
      flex-direction: row;
    }
    a {
      text-decoration: underline;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      // width: 100%;
      margin: 0 calc(var(--bs-gutter-x, 1.25rem) / 2);
      width: calc(100% - var(--bs-gutter-x, 1.25rem));
      height: 1px;
      background-color: #7575bf;
    }
  }

  &__socials {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
}
