@media (hover: hover) and (pointer: fine) {
  .scroll-bound {
    .bound-content {
      width: 100%;
      position: sticky;
      top: 0;
    }
    @include media-breakpoint-up(sm) {
      min-height: 150vh;

      .bound-content {
        min-height: 100vh;
        height: 740px;
        position: sticky;
        > * {
          width: 100%;
          height: 100%;
        }
      }
    }

    @include media-breakpoint-only(sm) {
      min-height: 560px;
      max-height: 560px;
    }
  }
}

.hero-section {
  @extend .section--dark;
  height: 1px;
  position: relative;
  padding-top: var(--header-height);
  padding-bottom: 50px;
  background-image: url("../img/gekkard-hero-hex-BG-desk.png");
  overflow: hidden;
  color: #ffffff;

  @include media-breakpoint-only(xs) {
    height: 100vh;
    min-height: 808px;
    max-height: 900px;
    &__content {
      text-align: left;
    }
    &__title {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0.875rem;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 105px;
      background-image: linear-gradient(
        to top,
        #324087 30%,
        rgba(#324087, 0.45),
        transparent
      );
    }
  }

  @include media-breakpoint-only(sm) {
    min-height: 560px;
    max-height: 560px;
    padding-bottom: 50px;
  }

  @include media-breakpoint-up(md) {
    min-height: 740px;
    max-height: 100vh;
    padding-bottom: 70px;
  }

  &__content {
    position: relative;
    padding-top: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    @include media-breakpoint-only(sm) {
      padding-top: 3rem;
    }
    @include media-breakpoint-up(md) {
      padding-top: 6rem;
    }

    .row,
    .row > * {
      height: 100%;
    }
  }

  &__title {
    line-height: 1.2;
    @include media-breakpoint-only(sm) {
      font-size: 2.125rem;
    }
  }

  &__desc {
    line-height: 1.33;
    width: 100%;
    @include media-breakpoint-up(sm) {
      font-size: 1.125rem;
    }
  }

  &__form {
    @include media-breakpoint-only(xs) {
      display: none !important;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;

    @include media-breakpoint-only(xs) {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    @include media-breakpoint-up(md) {
      padding: 0;
      margin-top: 4rem;
    }
    .app-icons {
      margin-top: 2.625rem;
      .app-store {
        width: 140px;
        margin-right: 26px;
      }
      .google {
        width: 146px;
      }
    }
  }

  &__btn {
    @include media-breakpoint-down(sm) {
      margin-top: 73px;
      padding: 0;
    }
  }

  &__img {
    position: absolute;
    top: 2%;
    left: 0;
    object-fit: cover;
    width: auto;
    min-width: 100%;
    height: 98%;
    max-height: 98%;
    z-index: 1;
    user-select: none;
    pointer-events: none;

    @include media-breakpoint-only(sm) {
      right: -18%;
      left: unset;
      max-height: 110%;
      height: 110%;
      width: auto;
    }
    @include media-breakpoint-up(sm) {
      top: 50%;
      width: 100%;
      height: auto;
      transform: translateY(-50%);
    }
  }

  [class*="gp_000"] {
    max-width: 100vw;
    position: absolute;
    object-fit: contain;
    transform: translateY(-40%) translateX(16%);

    @include media-breakpoint-only(sm) {
      transform: translateY(-37%) translateX(0%);
    }

    &.hide,
    &:not(.show) {
      opacity: 0;
    }
    &.show {
      opacity: 1;
    }
  }
}
