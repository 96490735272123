.external-link {
  display: inline-block;
  width: var(--link-w);
  height: var(--link-h);
  transition: $transition-fade;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 400px) {
    --link-w: 144px;
    --link-h: 42px;
    .me-2 {
      margin: 0;
    }
  }

  @media screen and (min-width: 400px) and (max-width: 660px) {
    --link-w: 168px;
    --link-h: 48px;
  }

  @include media-breakpoint-up(sm) {
    --link-w: 148px;
    --link-h: 44px;
  }

  img {
    width: 100%;
  }
}
