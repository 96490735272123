%btn--gradient {
  background: var(--btn--gradient);
  background-size: 150% 100%;
  background-position: 100% 0;
}

.btn {
  position: relative;

  &--large {
    min-width: 224px;
  }

  &--gradient {
    width: 224px;
    border-radius: $border-radius-pill;
    font-family: "FavoritC", Roboto, system-ui, sans-serif;
    @extend %btn--gradient;

    @include media-breakpoint-up(md) {
      width: auto;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -40%);
      width: 90%;
      height: 88%;
      z-index: -1;
      border-radius: inherit;
      filter: blur(10px);

      background: var(--btn--gradient-shadow);

      background-size: 150% 100%;
      background-position: 100% 0;
      @include transition($btn-transition, transform 0.2s ease-in);
    }

    &:hover {
      background-position: 0% 0;
    }
    &:hover::after {
      background-position: 80% 0;
      transform: translate(-50%, -50%);
    }
  }

  &--outlined {
    color: $white;

    .outline {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      z-index: -1;

      .outline-fill {
        opacity: 0;
        transition: opacity 0.4s ease;
      }
    }

    &:hover {
      .outline-fill {
        opacity: 1;
      }
    }
  }
}

// // TODO: remove later
.btn-link {
  display: none !important;
}
