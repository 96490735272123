.your-card-section {
  padding-top: var(--px);
  padding-bottom: var(--px);

  &__links {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-auto-rows: max-content;
    grid-gap: 1rem;
  }

  @include media-breakpoint-only(xs) {
    --px: 2rem;

    .container {
      position: relative;
      padding-top: 1rem;
      padding-bottom: 180px;
    }

    &__actions {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &__img {
      max-width: 110%;
      margin-left: -5%;
    }

    &__links {
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.5rem;
    }

    .note-list {
      text-align: center;
    }
  }

  @include media-breakpoint-only(sm) {
    --px: 3.5rem;

    &__img {
      max-width: 120%;
      margin-left: -10%;
    }

    .note-list {
      margin-top: 2rem;
    }
  }

  @include media-breakpoint-only(md) {
    &__img {
      margin-left: -5%;
    }
  }

  @include media-breakpoint-up(md) {
    --px: 100px;

    &__img {
      max-width: 120%;

      &-cont {
        margin-top: -4%;
      }
    }

    .note-list {
      margin-top: 3.625rem;
    }

    &__links {
      grid-template-columns: 3fr 4fr;
      grid-column-gap: 4rem;
    }
  }
}
