.faq-section {
  .section__title {
    margin-top: -20px;
    @include margin-bottom(3rem);
  }

  &__list {
    .accordion-item {
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        margin-bottom: 20px;
      }
      .accordion-header {
        .accordion-button {
          color: #101010;
          &::before {
          }

          &:not(.collapsed) {
            color: black;
          }

          &:focus {
            border: none;
            box-shadow: none;
          }
        }
      }
    }
  }

  .accordion-body {
    p:last-child() {
      margin-bottom: 0;
    }
    .list {
      text-align: left;
      &.unordered {
        list-style: none;
      }
      &:not(.unordered) {
        list-style: disc;
        li {
          margin-left: 1.5rem;
        }
      }
      &.numbered {
        list-style: decimal;
      }
    }
  }
}
