.crypto-section {
  .container {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2.5rem;
      width: 100%;
      height: 1px;
      background-color: $border-color;
      @include media-breakpoint-down(md) {
        margin-left: var(--bs-gutter-x, 1.25rem);
        margin-right: var(--bs-gutter-x, 1.25rem);
        width: calc(100% - var(--bs-gutter-x, 2.5rem));
      }
      @include media-breakpoint-up(sm) {
        bottom: -4rem;
      }
    }
  }

  &__title {
    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
      line-height: 1.33;
    }

    & + p {
      font-size: 1.125rem;
      line-height: 1.33;
    }
  }

  &__additional {
    @include margin-bottom(1.875rem);
    @include media-breakpoint-only(xs) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media screen and (max-width: 420px) {
      align-items: flex-start;
      text-align: left;
    }
    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: 2fr 2.5fr;
      grid-template-rows: auto;
      grid-gap: 0.5rem 1rem;
      margin-bottom: 3.25rem;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr 1fr;
      margin-right: -40px;
    }
  }

  &__info-item {
    position: relative;
    padding-left: 2.75rem;

    @include margin-top(0.75rem);
    @include margin-bottom(0.75rem);

    &::before {
      content: "";
      position: absolute;
      display: inline-block;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 110%;
      padding-left: 2.25rem;
      background-repeat: no-repeat;
      background-position: center top;

      @include media-breakpoint-up(md) {
        padding-left: 2.75rem;
      }
    }
    &.c-chart::before {
      background-image: url("../img/icons/crypto-chart.svg");
    }
    &.c-pie::before {
      background-image: url("../img/icons/crypto-pie.svg");
    }
    &.c-aml::before {
      background-image: url("../img/icons/crypto-aml.svg");
    }
    &.c-qualification::before {
      background-image: url("../img/icons/crypto-qualification.svg");
    }
    &.c-protection::before {
      background-image: url("../img/icons/crypto-protection.svg");
    }
  }

  &__actions {
    flex-direction: column;

    @include media-breakpoint-only(xs) {
      align-items: center;
    }

    @media screen and (max-width: 420px) {
      padding: 0;
    }
    @include media-breakpoint-up(md) {
      align-items: flex-start;
    }
  }

  &__img {
    @include margin-top(-1.5rem);
    @include margin-bottom(-6rem);
  }

  &__img-container {
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      @include media-breakpoint-only(sm) {
        max-width: 120%;
        margin: 0 -15%;
      }
      @include media-breakpoint-up(md) {
        max-width: 110%;
        margin: 0 -5%;
      }
    }
  }

  &__notes {
    @include margin-top(2.75rem);
    margin-bottom: 0;
  }
}
