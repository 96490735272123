.section {
  padding: 4rem 0;
  &--dark {
    --headings-color: #ffffff;
    padding: 3% 0;
    color: #ffffff;
    background: #324087 center/cover no-repeat;
    background-image: linear-gradient(
      252.47deg,
      #262262 -14.77%,
      #3f3991 83.55%
    );
  }

  @include media-breakpoint-only(xs) {
    padding: 2.5rem 0;
    text-align: center;

    &__title {
      max-width: 90%;
    }
    &__desc {
      max-width: 95%;
    }
    &__title,
    &__desc {
      margin-left: auto;
      margin-right: auto;
    }

    &__actions {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 1.875rem;
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include media-breakpoint-only(xs) {
      justify-content: center;
      padding-left: calc(var(--bs-gutter-x) / 2);
      padding-right: calc(var(--bs-gutter-x) / 2);

      .btn-link {
        margin-top: 25px;
      }
      .note-list {
        margin-top: 1.5rem;
      }
    }

    @include media-breakpoint-only(sm) {
      align-items: flex-start;
      margin-top: 2rem;

      .btn-link {
        display: none;
      }
      .note-list {
        margin-top: 1.75rem;
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      :not(.crypto-section) & {
        margin-top: 2rem;
      }
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      margin-top: 5rem;
    }
  }
}
