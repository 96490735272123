.navbar-toggler {
  width: 42px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  span {
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: $animate-duration;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 9px;
      width: 78%;
      right: 0;
      left: unset;
    }
    &:nth-child(3) {
      top: 18px;
    }

    background-color: #ffffff;
    .header--light & {
      background-color: #262626;
    }
  }

  &[aria-expanded="true"] {
    z-index: 2;
    span {
      background-color: #656565;
      &:nth-child(1) {
        top: 9px;
        transform: rotate(135deg);
      }
      &:nth-child(2) {
        opacity: 0;
        right: -60px;
      }
      &:nth-child(3) {
        top: 9px;
        transform: rotate(-135deg);
      }
    }
  }
}
