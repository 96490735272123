:root {
  --logo-dark-color: #262262;
  --logo-light-color: #ffffff;
  --btn--gradient: linear-gradient(80deg, #79dbff, #00aeef, #72bf44 90%);
  --btn--gradient-shadow: linear-gradient(
    80deg,
    #00aeef 10%,
    #00aeef 60%,
    #72bf44 90%
  );

  --header-padding-y: 0.75rem;
  --logo-height: 33px;
  --header-height: calc(var(--logo-height) + calc(var(--header-padding-y) * 2));

  @include media-breakpoint-up(md) {
    --header-padding-y: 1.5rem;
    --logo-height: 45px;
  }
}

/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
  @include media-breakpoint-up(sm) {
    html {
      // HACK: to prevent body overscroll on apple
      overflow: hidden;
      height: 100vh;
    }
    body {
      overflow: auto !important;
      height: 100%;
    }

    .body-wrap,
    .section {
      overflow: hidden;
    }
  }
}

body {
  overflow-x: hidden;
  font-family: var(--bs-font-sans-serif);
  -webkit-font-smoothing: antialiased;
}
