.select-wrap {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d2d2d2;
  outline: none;
  color: #ffffff;
  position: relative;

  @include media-breakpoint-up(md) {
    padding: 12px 14px 12px 4px;
    font-size: 16px;
    line-height: 21px;
  }

  &.show {
    &::after {
      transform: scaleY(-1);
    }

    .dropdown-menu {
      display: block;
    }
  }

  select {
    display: none;
  }

  &::after {
    content: "";
    background: url("../../img/icons/arrow.svg");
    width: 14px;
    height: 8px;
    position: absolute;
    right: 0;
    top: calc(50% - 4px);
  }

  .dropdown-menu {
    display: none;
    min-width: 100%;
    left: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
      0 0 0 0 rgba(0, 0, 0, 0.12);
    padding: 0.5rem;
    .dropdown-item {
      padding: 0.5rem 0.5rem 0.5rem 0;
    }
  }
}
