.carousel {
  outline: none;
  padding: 24px 0;

  &__container {
    position: relative;
  }
  &__styled-container {
    max-width: 100vw;
    transform: translate3d(0px, 0px, 0px);
    overflow-x: hidden;

    &::before,
    &::after {
      position: absolute;
      width: 5rem;
      height: 100%;
      top: 0px;
      background: linear-gradient(
        90deg,
        rgb(255, 255, 255) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    &::before {
      left: 0px;
    }
    &::after {
      right: 0px;
      transform: scaleX(-1);
    }
  }

  &__wrap {
    display: flex;
    width: 100%;

    @include media-breakpoint-down(md) {
      overflow-x: auto;
    }

    @include media-breakpoint-up(md) {
      width: auto;
      margin: 0 -40px;
      padding: 0 40px;
      animation: 50s linear 0s infinite normal none infinteScroll;
    }
  }

  &__item {
    @include media-breakpoint-down(md) {
      &:last-child() {
        .feedback-card__inner {
          margin-right: 1.25rem;
        }
      }
    }
    a {
      color: $black;
    }
  }
}

@keyframes infinteScroll {
  100% {
    transform: translate3d(-100%, 0px, 0px);
  }
}
