.mobile-banking {
  padding-bottom: 4rem;

  @include media-breakpoint-up(md) {
    margin-top: -4rem;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 0;
    padding-right: 0;
  }
}

.banner {
  position: relative;
  overflow: hidden;
  padding-top: 80px;

  @include media-breakpoint-up(sm) {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 80px;
      background: linear-gradient(
        to bottom,
        $body-bg,
        rgba($body-bg, 0.5),
        rgba($body-bg, 0)
      );
      z-index: 20;
    }
  }

  &__inner {
    padding: 0;
    position: relative;
    z-index: 1;
    border-radius: 6px;
  }

  &__bg-pattern {
    position: absolute;
    width: 100%;
    @include media-breakpoint-up(sm) {
      max-width: 40%;
    }
    height: auto;

    &.pat1 {
      top: 0;
      right: 0;
    }
    &.pat2 {
      bottom: 0;
      left: 0;
    }
  }

  &__content {
    position: relative;
    z-index: 20;
    border-radius: 6px;
    @include padding-top(2rem);
    @include padding-bottom(3rem);

    @include media-breakpoint-only(xs) {
      padding-bottom: 0;
      @include padding-left(1.5rem);
      @include padding-right(1.5rem);

      > * {
        text-align: center;
        max-width: 470px;
        margin: 0 auto;
      }
    }

    @include media-breakpoint-up(md) {
      padding-top: 3.125rem;
      padding-bottom: 3.375rem;
    }

    > * {
      @include media-breakpoint-down(md) {
        padding: 0;
      }
    }
  }

  &__phone-mob {
    max-height: 500px;
    width: auto;
    object-fit: contain;
    transform: translateY(7%);
    margin-top: -70px;
  }

  .section__actions {
    @include margin-top(2.625rem);
    @include media-breakpoint-only(xs) {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 20;
      width: 100%;
      @include padding-bottom(2.25rem);
    }
    @include media-breakpoint-up(md) {
      margin-top: 4rem;
    }
  }
}

.banner-phone {
  position: absolute;
  right: 8.33333%;
  z-index: 2;
  max-width: 160px;
  object-fit: contain;
  object-position: bottom;

  @include media-breakpoint-up(md) {
    max-width: 180px;
  }
}
