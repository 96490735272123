.input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d2d2d2;
  outline: none;
  color: #ffffff;

  @include media-breakpoint-up(md) {
    padding: 12px 0 12px 12px;
    font-size: 16px;
    line-height: 21px;
  }

  &::placeholder {
    font-weight: normal;
    color: #ffffff;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 21px;
    }
  }
}
