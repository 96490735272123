.benefit-card {
  color: $black;

  &__inner {
    display: flex;
    align-items: center;
    height: 68px;
    background-color: white;
    padding-right: 1rem;
    @include media-breakpoint-up(md) {
      height: 82px;
    }
  }

  &__img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 110px;
    @include media-breakpoint-up(md) {
      width: 88px;
    }
  }
}
