.navbar-nav {
  border-top: 1px solid #e4e4e4;
  padding-left: 35px;
  width: 100%;

  @include media-breakpoint-down(md) {
    text-align: left;
  }

  .nav-item {
    padding: 10px 0 10px 0;
    color: #1a1a1a;

    &:first-child() {
      padding-top: 1rem;
    }

    .nav-link {
      color: inherit;
      font-size: 18px;
      padding: 0;

      &::before {
        border-top: 6px solid;
        border-right: 4px solid transparent;
        border-bottom: 0;
        border-left: 4px solid transparent;
        position: absolute;
        left: -16px;

        top: 28.5px;
      }

      &.show {
        &::before {
          transform: scaleY(-1) translateY(3px);
        }
      }
    }

    .dropdown-menu {
      border: 0;
      border-top: 1px solid #e4e4e4;
      border-bottom: 1px solid #e4e4e4;
      border-radius: 0;
      transform: translateX(-5px);
      padding: 0;
      margin-top: 4px;

      .dropdown-item {
        padding: 0.5rem 0 0.5rem 16px;
        font-size: 18px;

        &:first-child() {
          padding-top: 11px;
        }

        &:last-child() {
          padding-top: 11px;
        }
      }
    }
  }
}
