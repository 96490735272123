.socials {
  margin: 0;
  padding: 0;
}

.social {
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #3d3d8f;
    transition: $transition-base;
    &:hover,
    &:focus {
      background-color: darken(#3d3d8f, 8%);
    }
  }
}
