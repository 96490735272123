$coin-size: 110px;

@keyframes coin-pulse {
  0% {
    transform: scale(0.6) translateX(-50%);
  }
  100% {
    transform: scale(1) translateX(-50%);
  }
}

.money-transfer-section {
  .row {
    align-items: center;
  }

  .money-transfer-image {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: translate(-10%, -10%);
      z-index: -1;
      background-image: url("../img/hex_pattern_phone_bg.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__coin-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    .coin {
      position: relative;
      width: $coin-size;
      height: $coin-size;

      &::before {
        content: "";
        position: absolute;
        bottom: 2px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 1rem);
        height: calc(100% - 1rem);
        border-radius: 50%;
        background: #1ee4f1;
        filter: blur(20px);
        z-index: -1;
        transform-origin: 0 center;
        animation: coin-pulse 2s infinite alternate;
      }

      img {
        width: 100%;
      }
    }
  }

  &__sepa {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
