.sepa-swift {
  display: inline-block;
  align-items: center;
  padding: 1.5rem 0.5rem;

  @include media-breakpoint-only(xs) {
    padding: 0 0.5rem 0.75rem;
    justify-content: space-between;
  }

  &__logo-text {
    margin-right: 1rem;
    font-family: "FavoritBoldC", sans-serif;
    font-weight: 700;
    color: #4b4b99;
  }
}
