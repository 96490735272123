.f-legal {
  &__link {
    font-family: "FavoritLightC";
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.25;
    color: #ffffff;
    opacity: 0.6;
  }
  &__copyright {
    // position: absolute;
    // bottom: 0;
    // z-index: 1;
    // padding-bottom: $spacer * 1.5;
    margin-left: auto;
    margin-right: auto;
    max-width: 46.5rem;
    font-size: 0.875rem;
    line-height: 1.2;
    color: #9797cd;
    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }
}
