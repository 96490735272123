.icon-text {
  @include link-with-icon($white);

  --i-w: 3.375rem;
  --i-h: 2.5rem;

  &.bordered {
    border: 1px solid #5751ad;
    padding: 1rem 0.75rem 1rem 0;
  }
  &.bordered &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media-breakpoint-only(xs) {
    font-size: 0.875rem;
  }
  @include media-breakpoint-only(sm) {
    --i-w: 3.75rem;
  }
  @include media-breakpoint-up(md) {
    --i-w: 4.125rem;
  }
}
