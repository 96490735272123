.account-section {
  @extend .section--dark;
  color: #ffffff;
  position: relative;
  padding: 0;
  z-index: 0;
  overflow: visible;

  @include media-breakpoint-only(xs) {
    & + .section {
      padding-top: 10rem;
    }
  }

  @include media-breakpoint-up(sm) {
    background: linear-gradient(
      278.71deg,
      #262262 -6.41%,
      #262262 -6.4%,
      #4b4b99 98.27%
    );

    & + .section {
      margin-top: 5.5rem;
    }
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 395px;
    max-width: 100vw;
    height: 395px;
    background: url("../img/hex_pattern.png") center no-repeat;
  }

  &__content {
    --pt: 3rem;
    --pb: 6.5rem;

    position: relative;
    z-index: 1;
    padding-top: var(--pt);
    padding-bottom: var(--pb);

    @include media-breakpoint-only(sm) {
      --pt: 4rem;
      --pb: 4.375rem;
    }
    @include media-breakpoint-up(sm) {
      --pt: 5.75rem;
      --pb: 3rem;
    }
  }

  .section__desc {
    @include media-breakpoint-only(xs) {
      margin-bottom: 400px;
    }
    @media screen and (max-width: 413px) {
      margin-bottom: 320px;
    }
  }

  &__icons {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin-top: 2rem;
    @include media-breakpoint-up(md) {
      margin-top: 5rem;
      padding-right: 30px !important;
    }
  }

  &__map-container {
    position: absolute;
    top: 0;
    right: 0;
    max-height: 100%;
    overflow: hidden;
    @include media-breakpoint-only(xs) {
      left: 0;
      right: unset;
      height: 100%;
      width: 100%;
    }

    &::after {
      content: "";
      position: absolute;

      @media screen and (max-width: 414px) {
        bottom: 0;
        left: 0;
        height: 19rem;
        width: 100%;
        background: transparent
          linear-gradient(
            to top,
            #2b2869 30%,
            rgba(#2b2869, 0.65),
            rgba(#2b2869, 0)
          );
      }

      @include media-breakpoint-up(xl) {
        right: 0;
        top: 0;
        width: 8vw;
        height: 100%;
        background: transparent
          linear-gradient(
            to left,
            #2b2869 30%,
            rgba(#2b2869, 0.65),
            rgba(#2b2869, 0)
          );
      }
    }

    img,
    #map-points {
      @include media-breakpoint-only(xs) {
        width: 820px;
        margin-top: 215px;
        transform: translate(-11%, 0);
        left: 0;
      }
      @media screen and (max-width: 413px) {
        width: 710px;
        margin-top: 255px;
      }
      @media screen and (max-width: 360px) {
        width: 650px;
        margin-top: 280px;
      }
      @include media-breakpoint-only(sm) {
        width: 90vw;
        transform: translate(35%, 7%);
      }
      @include media-breakpoint-only(md) {
        width: 80vw;
        height: 980px;
        transform: translate(20%, -3%);
      }
      @include media-breakpoint-up(lg) {
        width: 988px;
        height: 1029px;
        transform: translate(20%, -3%);
      }
      @include media-breakpoint-up(xl) {
        transform: translate(0%, -10%);
      }
    }

    #map-points {
      position: absolute;
      top: 0;
      right: 0;

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }

  &__terminal {
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;

    @include media-breakpoint-only(xs) {
      img {
        transform: translateY(50%);
        max-width: 540px;
      }
    }

    @include media-breakpoint-up(sm) {
      width: 100%;
      img {
        transform: translate(10%, 43%);
        height: auto;
        max-width: 700px;
      }
    }
  }
}
