.note-list {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  text-align: left;

  dt {
    @extend .visually-hidden;
  }

  dd {
    @include media-breakpoint-only(sm) {
      margin-bottom: 0.6rem;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 0.9rem;
    }
  }
  @include media-breakpoint-only(xs) {
    font-size: 0.875rem;
  }
}

.note {
  color: $link-color;
  font-size: 1.125em;
}
