.feedback-card {
  display: flex;
  align-items: center;
  height: 300px;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1rem;
    padding: 1.5rem;
    width: 80vw;
    max-width: 400px;
    height: 236px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    @include media-breakpoint-up(lg) {
      width: 306px;
      margin-left: 1.25rem;
    }
  }

  &__text {
    font-family: "FavoritLightC";
    font-weight: 100;
    font-size: 1rem;
    margin-top: 0.375rem;
  }

  &__company {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 1.125rem;
  }

  &__company-icon {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 1rem;
    border-radius: 50%;
  }
}
