@mixin font-face($name, $file, $weight: 400, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    src: url("../fonts/#{$name}/#{$file}.eot");
    src: url("../fonts/#{$name}/#{$file}.eot?#iefix")
        format("embedded-opentype"),
      url("../fonts/#{$name}/#{$file}.woff2") format("woff2"),
      url("../fonts/#{$name}/#{$file}.woff") format("woff");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin link-with-icon($color: #101010) {
  display: flex;
  align-items: center;
  font-family: "FavoritBookC", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  color: $color;

  &__icon-container {
    min-width: var(--i-w, 3.375rem);
    max-width: var(--i-w, 3.375rem);
  }
}
