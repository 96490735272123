//
// Base styles
//

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: $accordion-button-padding-y $accordion-button-padding-x;
  @include font-size(1.125rem);
  line-height: 1.5;
  color: $accordion-button-color;
  text-align: left; // Reset button style
  background-color: $accordion-button-bg;
  border: none;
  @include border-radius(2px);
  overflow-anchor: none;
  @include transition($accordion-transition);

  &:not(.collapsed) {
    color: $accordion-button-active-color;
    background-color: $accordion-button-bg;

    &::before {
      transform: $accordion-icon-transform;
    }
    &::after {
      opacity: 1;
    }
  }

  // Accordion button bot border
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: calc(100% - #{$accordion-button-padding-x * 2});
    height: $accordion-border-width;
    background-color: $accordion-border-color;
    opacity: 0;
    @include transition(opacity 0.12s ease-in);
  }

  // Accordion icon
  &::before {
    content: "";
    margin-right: 0.85rem;
    flex-shrink: 0;
    width: $accordion-icon-width;
    height: $accordion-icon-width;
    background-image: escape-svg($accordion-button-icon);
    background-repeat: no-repeat;
    background-size: $accordion-icon-width;
    @include transition($accordion-icon-transition);
  }

  &:hover {
    z-index: 2;
  }

  &:focus {
    z-index: 3;
    border-color: $accordion-button-focus-border-color;
    outline: 0;
    box-shadow: $accordion-button-focus-box-shadow;
  }
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: $accordion-bg;
  border: $accordion-border-width solid $accordion-border-color;
  @include border-radius(2px);

  position: relative;

  .accordion-collapse {
    &::after {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 4px 16px rgba($black, 0.1);

      @include border-radius(2px);
      z-index: -1;
    }
    &:not(.show)::after,
    &.collapsing::after {
      box-shadow: 0px 4px 16px transparent;
    }
  }
}

.accordion-body {
  font-family: "FavoritLightC", var(--bs-font-sans-serif);
  font-weight: 300;
  @include font-size($font-size-base);
  line-height: 1.25;
  padding: $accordion-body-padding-y $accordion-body-padding-x;
  color: $black;
}
