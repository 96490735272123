$tr-green: 7px;
$tr-white: 10px; //11

.map-transactions {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;

  .transaction {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    transform: translateY(-106%) translateX(-13px);
    @include padding-top(0.5rem);
    @include padding-bottom(0.5rem);
    @include padding-left(0.75rem);
    @include padding-right(0.75rem);
    background-color: white;
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
    font-weight: normal;

    @include media-breakpoint-up(sm) {
      min-width: 120px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
    }

    &::before {
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0px 4px 8px rgba($black, 0.25);
    }

    &::after {
      bottom: 2px;
      left: 0;
      transform: translateY(100%) translateX(12px);
      border: 0 solid transparent;
      border-left-width: $tr-white / 2 + 1;
      border-right-width: $tr-white / 2 + 1;
      border-top: $tr-white solid #fff;
    }

    &__sum {
      position: relative;
      padding-left: $tr-green + 2;
      line-height: 1.1875;
      color: #313131;

      @include font-size(16px);
      @include media-breakpoint-up(sm) {
        font-size: 16px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        border: 0 solid transparent;
        border-bottom-width: floor($tr-green / 2);
        border-top-width: floor($tr-green / 2);
        border-left: $tr-green solid #50da39;
      }
    }

    &__location {
      margin-top: 2px;
      line-height: 1.5;
      color: #868686;

      @include font-size(12px);
      @include media-breakpoint-up(sm) {
        font-size: 12px;
      }
    }
  }
}
