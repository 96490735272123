.link-img {
  @include link-with-icon();
  --i-w: 2.25rem;

  padding-top: $spacer * 0.25;
  padding-bottom: $spacer * 0.25;

  @include media-breakpoint-only(xs) {
    font-size: 0.875rem;
    img {
      margin-right: 9px;
    }
  }
  @include media-breakpoint-only(sm) {
    --i-w: 2.625rem;
  }
  @include media-breakpoint-up(md) {
    --i-w: 3rem;
  }
}
