.f-nav {
  display: flex;
  @include media-breakpoint-down(sm) {
    justify-content: space-between;
  }
  &__column {
    padding-right: 1rem;
  }
  &__list.light &__link {
    font-family: "FavoritLightC", sans-serif;
  }
  &__item {
    & + & {
      margin-top: 1rem;
    }
  }
  &__link {
    font-family: "FavoritBookC", sans-serif;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25;
    color: #ffffff;
  }
}
