.header {
  padding: var(--header-padding-y) 0;
  background-color: transparent;
  transition-property: background-color, padding;
  transition: $animate-duration / 1.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;

  &--light {
    --header-padding-y: 1rem;
    background-color: #ffffff;
    box-shadow: 0px 0.25rem 0.625rem rgba($black, 0.15);
  }

  @include media-breakpoint-down(md) {
    &:not(&--light)::after {
      content: "";
      position: absolute;
      bottom: -1px;
      height: 1px;
      width: 100%;
      opacity: 0.6;
      background-color: #5b55b1;
    }
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute !important;
    top: 0;
    right: 0;
    transform: translateX(100%);
    width: 64vw;
    height: 100vh !important;
    background-color: white;
    transition: transform $animate-duration;
    z-index: 1;

    padding-top: calc(var(--logo-height) + calc(var(--header-padding-y) * 2));

    &.show {
      transform: translateX(0);
      box-shadow: 0px 0.25rem 1rem rgba($black, 0.1);
    }
  }

  &__logo-link {
    padding: 0;
    width: 102px;
    height: var(--logo-height);

    img,
    svg {
      width: 100%;
    }

    svg path {
      transition: fill $animate-duration;
    }

    @include media-breakpoint-up(md) {
      width: 137px;
    }
  }

  &__btn {
    padding: 0.85rem $input-btn-padding-x/2;
    @include font-size(1.125rem);
    width: unset;
    display: none;

    &::after {
      height: 70%;
      width: 80%;
      top: 50%;
    }
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
